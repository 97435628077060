export default class BaseApi {
  constructor({ baseUrl, client }) {
    this.baseUrl = baseUrl;
    this.client = client;
  }

  async get(endpoint, options) {
    return this.client.get(`${this.baseUrl}${endpoint}`, options).json();
  }

  async post(endpoint, options) {
    return this.client.post(`${this.baseUrl}${endpoint}`, options).json();
  }

  async patch(endpoint, options) {
    return this.client.patch(`${this.baseUrl}${endpoint}`, options).json();
  }

  async put(endpoint, options) {
    return this.client.put(`${this.baseUrl}${endpoint}`, options).json();
  }

  async delete(endpoint, options) {
    return this.client.delete(`${this.baseUrl}${endpoint}`, options).json();
  }

  async head(endpoint, options) {
    return this.client.head(`${this.baseUrl}${endpoint}`, options).json();
  }
}
