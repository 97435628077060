import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
export * from './modules/auth';
import user from './modules/user';

Vue.use(Vuex);

let storeInstance = null;

export function getStore() {
  return storeInstance;
}

export function createStore({ persistenceKey, rehydrated }) {
  storeInstance = new Vuex.Store({
    strict: true,
    modules: {
      auth,
      user,
    },
    plugins: [
      createPersistedState({
        paths: ['auth'],
        key: persistenceKey,
        rehydrated,
      }),
    ],
  });

  return storeInstance;
}
