import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { ApiClient } from '@bureau700/core';
import vuetify from './plugins/vuetify';
import VueCookie from 'vue-cookie';

Vue.config.productionTip = false;

Vue.use(ApiClient, {
  baseUrl: process.env.VUE_APP_API_ENDPOINT,
  cookieHost: process.env.VUE_APP_COOKIE_DOMAIN,
});

const token = VueCookie.get('token');
if (token) {
  location.href = process.env.VUE_APP_HOST_APP;
}

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
