<template>
  <v-form
    id="signup-form"
    ref="form"
    v-model="valid"
  >
    <v-card class="register-box"
      :loading="loading"
      :loader-height="10"
    >
      <v-card-title>Register</v-card-title>

      <v-text-field
        class="register-input"
        outlined
        v-model="input.fullName"
        :rules="[rules.required]"
        label="Full name"
      ></v-text-field>

      <v-text-field
        class="register-input"
        outlined
        v-model="input.email"
        :rules="[rules.required, rules.mail]"
        label="E-mail"
        required
      ></v-text-field>

      <v-text-field
        class="register-input"
        outlined
        v-model="input.password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <vue-recaptcha 
        class="captcha"
        ref="recaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        size="normal"
        @verify="validateCaptcha"
      >
      </vue-recaptcha>

      <v-card-actions>
        <v-btn
          :disabled="!valid || loading || !captchaResponse"
          @click="register()"
        >Register</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Register',
  components: { VueRecaptcha },
  data: function () {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      loading: false,
      valid: true,
      input: {
        fullName: '',
        email: '',
        password: '',
      },
      showPassword: false,
      rules: {
        required: v => !!v || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        mail: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      },
      captchaResponse: null,
    };
  },
  methods: {
    validateCaptcha (response) {
      this.captchaResponse = response;
    },
    async register() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        try {
          await this.$api.user.signup(
            this.input.email,
            this.input.password,
            this.input.fullName,
            this.captchaResponse,
          );
          this.$emit('success', this.input.email);
        } catch (e) {
          this.$emit('error', e);
        }
        this.$refs.recaptcha.reset();
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
  .register-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    padding-bottom: 10px;
  }

  .register-input {
    width: 280px;
  }

  .captcha {
    margin-bottom: 14px;
  }
</style>
