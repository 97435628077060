import BaseApi from './base-api';

export default class UserApi extends BaseApi {
  signin(email, password) {
    const encodedHeader = btoa(`${email}:${password}`);

    return this.post('/signin', {
      headers: {
        Authorization: `Basic ${encodedHeader}`,
      },
    });
  }

  signup(email, password, fullName, captchaResponse) {
    return this.post('/signup', {
      json: {
        email,
        password,
        fullName,
        captchaResponse,
      },
    });
  }

  list({ page, pageSize, query }) {
    console.log({ page, pageSize, query });
    const searchParams = {
      page,
      pageSize,
    };
    if (query) {
      searchParams.query = query;
    }
    return this.get('/users', { searchParams });
  }

  me() {
    return this.get('/users/me');
  }
}
