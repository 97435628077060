<template>
  <v-form ref="form">
    <v-card
      class="login-box"
      :loading="loading"
      :loader-height="10"
      @keyup.enter="login()"
    >
      <v-card-title>Login</v-card-title>

      <v-text-field
        class="login-input"
        outlined
        v-model="input.email"
        :rules="[rules.required]"
        :disabled="loading"
        label="E-mail"
        autocomplete="off"
        required
      ></v-text-field>

      <v-text-field
        class="login-input"
        outlined
        v-model="input.password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="showPassword ? 'text' : 'password'"
        :disabled="loading"
        label="Password"
        autocomplete="off"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      
      <v-card-actions>
        <v-btn :disabled="loading" @click="login()">Login</v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
</template>

<script>
import {AccessDeniedError} from '@bureau700/core';

export default {
  name: 'Login',
  data: function () {
    return {
      loading: false,
      input: {
        email: '',
        password: ''
      },
      showPassword: false,
      rules: {
        required: v => !!v || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    };
  },
  methods: {
    isValid() {
      if (!this.input.email || !this.input.password) {
        return false;
      }
      return true;
    },
    async login() {
      if (this.isValid()) {
        this.loading = true;
        try {
          const { token } = await this.$api.user.signin(this.input.email, this.input.password);
          this.$api.setToken(token);

          location.href = process.env.VUE_APP_HOST_APP;
        } catch (e) {
          if (e instanceof AccessDeniedError) {
            this.$refs.form.reset();
          }
          
          this.$emit('error', e);
        } finally {
          this.loading = false;
        }
      }
    }
  }
}
</script>

<style scoped>
  .login-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    padding-bottom: 10px;
  }

  .login-input {
    width: 280px;
  }
</style>
