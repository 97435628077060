import ky from 'ky';
import VueCookie from 'vue-cookie';
import UserApi from './user';

let apiInstance = null;

export default class ApiClient {
  static install(Vue, options) {
    apiInstance = new ApiClient(options);
    Vue.prototype.$api = apiInstance;
  }

  constructor({ baseUrl, cookieHost }) {
    this.token = VueCookie.get('token');
    this.cookieHost = cookieHost;
    this.client = ky.extend({
      hooks: {
        beforeRequest: [
          options => {
            if (this.token) {
              options.headers.set('authorization', `Bearer ${this.token}`);
            }
          },
        ],
        afterResponse: [
          (_request, _options, response) => {
            if (response.status == 401) {
              this.token = '';
              VueCookie.delete('token', { domain: this.cookieHost });
            }
          },
        ],
      },
    });

    this.user = new UserApi({
      client: this.client,
      baseUrl,
    });
  }

  setToken(token) {
    this.token = token;
    if (token?.length) {
      VueCookie.set('token', token, { expires: '1Y', domain: this.cookieHost });
    } else {
      VueCookie.delete('token', { domain: this.cookieHost });
    }
  }
}

export function getApi() {
  if (!apiInstance) {
    throw new Error('API has not been initialized!');
  }

  return apiInstance;
}
