import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie';
import Signin from '../views/Signin.vue';
import Signup from '../views/Signup.vue';
import SignupSuccess from '../views/SignupSuccess.vue';

const token = VueCookie.get('token');
const notAuthenticatedGuard = (to, from, next) => {
  try {
    if (!token) {
      next();
      return;
    }
    location.href = process.env.VUE_APP_HOST_APP;
  } catch (err) {
    console.log(err);
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'Signin' },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    beforeEnter: notAuthenticatedGuard,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: notAuthenticatedGuard,
  },
  {
    path: '/signup/:email',
    name: 'SignupSuccess',
    component: SignupSuccess,
    beforeEnter: notAuthenticatedGuard,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
