import { getApi } from '../../api';

export class AccessDeniedError extends Error {}

const auth = {
  namespaced: true,
  state: {
    token: '',
  },
  mutations: {
    setToken(state, token) {
      getApi().setToken(token);
      state.token = token;
    },
  },
  actions: {
    async adminLogin({ commit, dispatch, rootState }, { email, password }) {
      const { token } = await getApi().user.signin(email, password);
      commit('setToken', token);
      await dispatch('user/fetchUser', null, { root: true });

      if (!rootState.user.admin) {
        dispatch('logout');
        throw new AccessDeniedError();
      }
    },
    async login({ commit }, { email, password }) {
      const { token } = await getApi().user.signin(email, password);
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('setToken', '');
    },
    setToken({ commit }, { token }) {
      commit('setToken', token);
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
  },
};

export default auth;
