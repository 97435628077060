<template>
  <div class="register-container">
    <v-alert v-if="error"
      dense
      dismissible
      outlined
      text
      type="error"
      @click="error = ''"
    >
      {{ error }}
    </v-alert>

    <Register
      @error="handleError"
      @success="handleSuccess"
    />

    <v-alert
      class="info-message"
      dense
      outlined
      type="info"
    >
      Already have an account ? 
      <router-link to="/signin">Sign-in.</router-link>
    </v-alert>
  </div>
</template>

<script>
import Register from "@/components/Register.vue";

export default {
  name: 'Signup',
  components: {
    Register,
  },
  data() {
    return {
      error: '',
    };
  },
  methods: {
    handleSuccess(email) {
      this.$router.push({ path: `/signup/${email}` });
    },
    handleError(e) {
      this.error = e.message ?? e;
    }
  }
};
</script>
<style scoped>
  .register-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .info-message {
    margin: 20px;
    width: 500px;
    display: flex;
    justify-content: center;
  }
</style>
